<template>
  <div id="vital1" class="company_content">
    <div class="index1">
      <!-- <div class="foot"></div>
      <div class="youba">YOUBA</div>
      <div class="youba_desc">{{ desc }}</div> -->
      <div class="about">
        <div class="about_youba"><span>关于</span>天华科技</div>
        <div class="about_desc">专注信息化项目，提供数字化解决方案</div>
        <div class="about_desc">量身定制智能综合服务平台</div>
        <el-button @click="showDialog" class="link_btn">联系我们</el-button>
      </div>
    </div>
    <div class="idea">
      <div class="idea_box">
        <div class="title">品牌理念</div>
        <div class="slogan">
          <div class="flex slogan_row">
            <div class="blue_box">诚</div>
            <div class="normal_box">信</div>
            <div class="normal_box">务</div>

            <div class="normal_box" style="color: #0156ee">实</div>
          </div>
          <div class="flex slogan_row">
            <div class="blue_box">开</div>
            <div class="normal_box">拓</div>
            <div class="normal_box">创</div>

            <div class="normal_box" style="color: #0156ee">新</div>
          </div>
        </div>
        <div class="slogan2">
          我们专注于数字化政务服务与系统产品领域，凝聚核心竞争力
        </div>
      </div>
      <div class="bg_box">
        <div class="title">企业背景</div>
        <div class="bg_desc">{{ bsDesc }}</div>
      </div>
      <div class="hope">
        <div class="title">我们希望</div>
        <div class="hope_container">
          <div class="hope_left">
            <div>宗旨、使命、价值观</div>
            <div class="line"></div>
          </div>
          <div class="hope_line"></div>
          <div class="hope_right">
            <div>成就客户、专业创新、团队服务</div>
            <div>服务提升政务客户的数字化工作效能</div>
            <div>创新发展理念、传承诚值文化、打造百年品牌、培育时代精英</div>
          </div>
        </div>
      </div>
    </div>

    <div class="team">
      <div class="title font_color">我们的团队</div>
      <div class="team_row">
        <div class="team_box">
          <div class="num_title">98.9%</div>
          <div class="type_title">满意度</div>
        </div>
        <div class="team_box">
          <div class="num_title">10000+</div>
          <div class="type_title">服务软件系统用户数</div>
        </div>
        <div class="team_box">
          <div class="num_title">15年+</div>
          <div class="type_title">大型项目实施经验</div>
        </div>
        <div class="team_box">
          <div class="num_title">25+</div>
          <div class="type_title">自主知识产权产品</div>
        </div>
      </div>
    </div>
    <div class="culture">
      <div class="title">企业文化</div>
      <div class="culture_row">
        <div class="culture_box_1">
          <div class="culture_title">企业管理理念</div>
          <div class="culture_desc">以精湛的技术为先导</div>
          <div class="culture_desc">以优质的服务为根本</div>
          <div class="culture_desc">以共赢的目标为追求</div>
          <div class="culture_desc">以人为本，团结和谐，敬业负责，共发展</div>
          <div class="culture_foot">
            <img :src="rightIcon" />
          </div>
        </div>
        <div class="culture_box_2">
          <div class="culture_title">企业管理理念</div>
          <div class="culture_desc">诚信务实 开拓创新</div>
          <div class="culture_desc">合作发展 服务社会</div>
          <div class="culture_foot">
            <img :src="rightIcon" />
          </div>
        </div>
        <div class="culture_box_3">
          <div class="culture_title">企业精神</div>
          <div class="culture_desc">创新发展理念 传承诚信文化</div>
          <div class="culture_desc">打造百年品牌 培育时代精英</div>
          <div class="culture_foot">
            <img :src="rightIcon" />
          </div>
        </div>
      </div>
    </div>
    <div class="safe">
      <div class="title font_color">多重保障</div>
      <div class="safe_row">
        <div class="safe_box" v-for="item in safeList">
          <img :src="item.iconUrl" />
          <div class="safe_title">{{ item.title }}</div>
          <div v-for="desc in item.descs" class="safe_desc">{{ desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "aboutUs",
  components: {},
  data() {
    return {
      dialogVisible: false,
      rightIcon: require("@/assets/culture/right.png"),
      safeList: [
        {
          title: "安全保障",
          descs: ["网络安全", "服务器安全", "系统安全"],
          iconUrl: require("@/assets/culture/icon1.png"),
        },
        {
          title: "服务保障",
          descs: ["技术咨询服务", "平台项目数据服务", "培训服务"],
          iconUrl: require("@/assets/culture/icon2.png"),
        },
        {
          title: "技术保障",
          descs: ["系统运行维护", "系统故障恢复", "系统配置调整"],
          iconUrl: require("@/assets/culture/icon3.png"),
        },
        {
          title: "时效保障",
          descs: ["2h内响应", "24h内提供解决方案", "应急管理程序解决故障"],
          iconUrl: require("@/assets/culture/icon4.png"),
        },
      ],
      bsDesc:
        "金华市天华网络科技有限公司成立于2007年，公司主要从事信息行业应用软件开发和技术服务，基于移动互联网与物联网、大数据和云计算等技术，开发大数据管理服务云平台。公司主要承接政府项目，为目标用户提供完整的信息化解决方案，量身研发对应需求的综合服务平台，实现大数据管理、业务支撑和综合监管功能合一。业务涵盖云数据中心、云服务大数据、智慧城市、智慧企业等业务板块，形成了覆盖基础设施、平台软件、数据信息和应用软件四个层面的整体解决方案服务能力，全面支撑政府、企业数字化转型。",
      desc: "“中国游巴网”（www.chinaU8.com）成立于2014年3月，是集行业监管、企业管理和社会化服务“三位一体”的综合性公共服务平台。具有“游巴监管、游巴大数据、游巴车管家、游巴集采、游巴出行、游巴商城”六大系统；为公众提供多样化的安全出行服务、完善的诚信评价与客诉体系，实现车源合法、便捷订车、价格透明。",

      developmentUrl:
        "https://imagepub.chinau8.cn/tianhua_jinhua/beishankou/development.png",
    };
  },
  mounted() {},
  methods: {
    showDialog() {
      this.$store.dispatch("dialogShow");
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("firstTrue");
    next();
    // 离开当前组件时候触发
  },
};
</script>

<style lang="scss">
.company_content {
  background-color: white;
}
.index1 {
  height: 1080px;
  background: url("https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/about_bg.png");
  background-size: 100% 100%;
  text-align: left;
  position: relative;
  // padding-left: 340px;
  .about {
    position: absolute;
    left: 130px;
    top: 210px;
    .about_youba {
      font-size: 48px;
      font-weight: bold;
      color: #39dcff;
      line-height: 56px;
      margin-bottom: 8px;
      span {
        color: #ffffff;
      }
    }
    .about_desc {
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      line-height: 48px;
      &:first-child {
        margin-top: 17px;
      }
    }
  }
  .foot {
    width: 763px;
    height: 348px;
    background: url("~@/assets/aboutNew/bg_foot.png");
    background-size: 100% 100%;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .youba {
    position: absolute;
    right: 550px;
    bottom: 230px;
    font-size: 42px;
    font-weight: bold;
    color: #ffffff;
    line-height: 49px;
  }
  .youba_desc {
    position: absolute;
    right: 60px;
    bottom: 50px;
    width: 642px;
    height: 152px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 38px;
  }
}
.link_btn {
  width: 160px;
  height: 54px;
  font-size: 20px;
  margin-top: 8px;
  color: #217eff;
}
.title {
  height: 54px;
  font-size: 36px;
  font-weight: bold;
  color: #333333;
  line-height: 42px;
  margin-bottom: 45px;
}
.font_color {
  color: #ffffff;
}
.idea {
  width: 100%;
  height: 1200px;
  background: url("https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/about_company_bg.png");
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 60px 210px;
  position: relative;
  .slogan {
    display: flex;
    justify-content: space-evenly;
    height: 80px;
    font-size: 48px;
    font-weight: 500;
    color: #333333;
    line-height: 80px;
    letter-spacing: 60px;
  }
  .slogan2 {
    width: 100%;
    background: url("https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/idea.png");
    background-size: 100% 100%;
    height: 200px;
    font-size: 32px;
    font-weight: 500;
    color: #ffffff;
    margin: 70px 0;
    line-height: 200px;
  }
}
.bg_desc {
  font-size: 28px;
  font-weight: 400;
  color: #666666;
  line-height: 56px;
  text-align: left;
  text-indent: 56px;
  margin-bottom: 70px;
}
.hope {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 60;
  .hope_container {
    width: 1052px;
    height: 188px;
    background: linear-gradient(45deg, #0350d7 0%, #265ff1 100%);
    opacity: 1;
    margin: 0 auto;
    display: flex;
    box-sizing: border-box;
    padding: 30px 50px 30px 120px;

    .hope_left {
      font-size: 26px;
      font-weight: bold;
      color: #ffffff;
      line-height: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .line {
        width: 149px;
        height: 3px;
        background: #ffffff;
        opacity: 1;
      }
    }
    .hope_line {
      width: 3px;
      height: 99px;
      background: #d9d9d9;
      opacity: 0.6;
      margin: 0 40px 0 60px;
      align-self: center;
    }
    .hope_right {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      line-height: 23px;
    }
  }
}

.team {
  width: 100%;
  height: 750px;
  background: url("https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/team.png");
  background-size: 100% 100%;

  position: relative;
  padding: 180px 300px 0 300px;
  box-sizing: border-box;
  .team_row {
    display: flex;
    justify-content: space-between;
    .team_box {
      flex: 1;
    }
    .num_title {
      font-size: 64px;
      font-weight: bold;
      color: #ffffff;
      line-height: 75px;
      margin-bottom: 25px;
    }
    .type_title {
      font-size: 32px;
      font-weight: 400;
      color: #ffffff;
      line-height: 38px;
    }
  }
}
.culture {
  width: 100%;
  height: 460px;
  padding: 60px 210px 0 210px;
  box-sizing: border-box;
  position: relative;
  background-color: #f4f7fa;
  .culture_row {
    width: 1500px;
    display: flex;
    position: absolute;
    bottom: -20px;
  }
  .culture_title {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    line-height: 28px;
    margin-bottom: 20px;
    text-align: left;
  }
  .culture_desc {
    font-size: 20px;
    font-weight: 500;
    color: #666666;
    line-height: 23px;
    margin-bottom: 18px;
    text-align: left;
  }
  .culture_foot {
    flex: 1;
    align-self: flex-start;
    display: flex;
    flex-direction: column-reverse;
    img {
      width: 36px;
      height: 16px;
    }
  }
  .culture_box_1 {
    flex: 7;
    height: 320px;
    padding: 40px;
    box-sizing: border-box;
    background: url("~@/assets/culture/culture1.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
  }
  .culture_box_2 {
    flex: 4;
    height: 320px;
    padding: 40px;
    box-sizing: border-box;
    background: url("~@/assets/culture/culture2.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
  }
  .culture_box_3 {
    flex: 4;
    height: 320px;
    padding: 40px;
    box-sizing: border-box;
    background: url("~@/assets/culture/culture3.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
  }
}
.safe {
  width: 100%;
  height: 602px;
  background: linear-gradient(225deg, #0751d2 0%, #1563ea 67%);
  opacity: 1;
  padding: 60px 260px 60px 260px;
  box-sizing: border-box;
  .safe_row {
    display: flex;
    justify-content: space-between;
    .safe_box {
      width: 289px;
      height: 334px;
      background: rgba(255, 255, 255, 0.1);
      opacity: 1;
      box-sizing: border-box;
      padding-top: 50px;
      img {
        width: 80px;
        height: 80px;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
    .safe_title {
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
      line-height: 23px;
      margin-top: 30px;
      transform: scale(1.1);
      margin-bottom: 14px;
    }
    .safe_desc {
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      margin-top: 10px;
    }
  }
}
.blue_box {
  width: 48px;
  background: #0156ee;
  opacity: 1;
  color: #ffffff;
  padding: 0 16px;
}
.slogan_row {
  width: 500px;
  height: 80px;
  justify-content: space-around;
  align-items: center;
}
.normal_box {
  width: 48px;
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
}
</style>
